import request from '../request'

export function fetchList(params) {
    return request({
        url: `/finance-service/subpackageCost/list`,
        params,
    })
}

// 上传 Excel 文件
export function upload(data) {
    return request({
        url: `/finance-service/subpackageCost/import`,
        method: "post",
        data
    })
}