

<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input
                placeholder="设计编号"
                style="width: 150px"
                v-model="form.prjCode"
              />
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                placeholder="项目名称"
                style="width: 150px"
                v-model="form.prjName"
              />
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                placeholder="凭证号"
                style="width: 150px"
                v-model="form.certNumber"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker v-model="form.date" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <Uploader />
              <a-button @click="download" type="primary"> 导出</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="日期" data-index="date" width="80px" />
        <a-table-column title="科目名称" data-index="ncCode" />
        <a-table-column
          title="项目名称"
          data-index="prjName"
          ellipsis="true"
          width="400px"
        />

        <a-table-column
          title="设计编号"
          data-index="prjCode"
          ellipsis="true"
          width="120px"
        />
        <a-table-column
          title="凭证号"
          data-index="certNumber"
          ellipsis="true"
          width="120px"
        />
        <a-table-column title="摘要" data-index="abstracts" ellipsis="true" />
        <a-table-column title="借方" data-index="debtAmount" ellipsis="true" />
        <a-table-column title="成本分类" data-index="costCategory" />
        <a-table-column title="更新方式" data-index="1" />
        <a-table-column title="更新日期" data-index="2" />

        <!-- <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <private-button path="edit" :params="{ id: text.id }">
              <span>编辑</span>
            </private-button>
            <private-button path="delete" :params="{ id: text.id }">
              <span>删除</span>
            </private-button>
          </template>
        </a-table-column> -->
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList } from "@/api/finance/subpackage";
import Uploader from "./components/uploader.vue";
import { downloadByHtml } from "@/utils/xlsx";

export default {
  name: "subpackage",
  mixins: [watermark],

  components: {
    Uploader,
  },

  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  activated() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;

      const { date } = this.form;
      let startDate, endDate;
      if (Array.isArray(date) && date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[1].format("YYYY-MM-DD");
      }

      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
        date: undefined,
        startDate,
        endDate,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.getList();
    },

    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },

    moneyFilter(money) {
      if (typeof money !== "number" || !money) {
        return 0;
      }

      const moneyStr = String(money);
      const arr = moneyStr.split(".");
      if (arr.length > 1) {
        return this.separateMoney(arr[0]) + "." + arr[1];
      } else {
        return this.separateMoney(moneyStr);
      }
    },

    separateMoney(moneyStr) {
      let arr = moneyStr.split("").reverse();

      let result = [];

      for (let i = 0; i < arr.length; i++) {
        if (i && i % 3 === 0) {
          result.push(",");
        }
        result.push(arr[i]);
      }

      return result.reverse().join("");
    },

    moneyColor(money) {
      if (parseFloat(money) < 0) {
        return {
          color: "#f00",
        };
      } else {
        return {
          color: "#1890ff",
        };
      }
    },

    download() {
      downloadByHtml(document.getElementsByTagName("table")[0], "账面分包");
    },
  },
};
</script>
